import React, {useState,useEffect} from 'react';
import * as Tone from "tone";

const ContextRunner = props => {
	const [audioState,setAudioState] = useState(false);
	if (audioState) {

	}
	const startContext = _=> {
		// if (Tone.context.state !== 'running') {
			console.log("set audiostate to true");
	    Tone.context.resume();
	    setAudioState(true);
	    props.reactivate(true);
	  // }
	}

	const focus = _ => {
		console.log("there is focus",Tone.context.state);
		window.location.reload(false);
	}
	const blur = _ => {
		console.log("there is blur");
	}
	

	useEffect(() => {
		console.log("this is happening")
		if (Tone.context.state !== 'running') {
			console.log("set audiostate to false");
			setAudioState(false);
		}	
		window.addEventListener('focus',focus);
		window.addEventListener('blur',blur);
		// const interval = setInterval(_ => {
		// 	console.log(window.onfocus);
		// },1000);
		// return () => {
		// 	clearInterval(interval)
		// }		
	},[])



	if (Tone.context.state !== 'running' || !audioState) {
	  return (
	  	<div id="contextStarter">
		  	<table>
		      <tbody>
		      <tr>
		        <td>
		          <button id="buttons" onClick={startContext}>Start</button>  
		        </td>
		      </tr>
		      <tr>
		        <td>
		          Klik op start om te beginnen<br/><br/>
		        </td>
		      </tr>
		      </tbody>
		    </table>
	    </div>
		);
	}
	else {
		return(
			<div>&nbsp;
			</div>
		);
	}
}

export default ContextRunner;