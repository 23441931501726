import React, {useEffect, useState} from 'react';
import PermissionButton from './PermissionButton';

let acc = {};
let sqrtVal = 0; //value for the sqrt of (x*x+y*y+z*z)
let sqrtSma = 0; //value for the summed moving avarage of sqrtVal
let deltaVal = 0; //delta value for sqrtSma
let deltaSma = 0; //sma of delta value
let prevSma = 0; //previous deltasma
let offDeltaThresh = -0.00 //threshold in delta-value for stop-trigger 
let onDeltaThresh = 0.02 //threshold in delta-value for start-trigger
let onSqrtThresh = 0.5; //threshold in sqrt-value for stop-strigger
let offSqrtThresh = 1.5; //threshold in sqrt-value for start-trigger
let interval = 16; //interval of sensor-reading

//class to calculate a simple moving avarage
class SimpleMovingAvarage {
  constructor(l) {
    this.dataList = [];
    this.length = l;
    this.smaValue = 0; 
  }
  run (data) {
    if(this.dataList.length > this.length) {
      this.dataList.shift();
    }
    this.dataList.push(data);
    this.smaValue = 0;
    for (let i in this.dataList) {
      this.smaValue += this.dataList[i];
    }
    return this.smaValue / this.dataList.length;
  }
}

const sqrtSmaCalc = new SimpleMovingAvarage(50);
const deltaSmaCalc = new SimpleMovingAvarage(10);

const roundToTwo = (num) => {
  return +(Math.round(num + "e+2")  + "e-2");
}

const handle = deviceMotionEvent => {
	//get the data from the device:
	acc = deviceMotionEvent.acceleration;
	interval = deviceMotionEvent.interval;

	//calulate different thresholds depending on update interval of device
	//difference between Android and IOS
	offDeltaThresh = (interval < 0.5)?-0.00:0;
  onDeltaThresh = (interval < 0.5)?0.02:0.02;
  offSqrtThresh = (interval < 0.5)?2.0:1.5;
  onSqrtThresh = (interval < 0.5)?1.5:0.5;
  sqrtVal = Math.sqrt(
    (acc.x * acc.x) + 
    (acc.y * acc.y) + 
    (acc.z * acc.z)
  );

  //calculate the sma of the sqrtVal:
  sqrtSma = sqrtSmaCalc.run(sqrtVal);
}

window.addEventListener("devicemotion",handle,true);

const MotionTracker = props => {
	const [smaChange,setSmaChange] = useState(0);
	const [data,setData] = useState();

	useEffect(() => {
		// console.log("baviaan");
		//function to be executed every 50ms:
		const checkUpdate = () => {

			//calculate delta-value, and sma of delta value
	    deltaVal = sqrtSma - prevSma;
	    deltaSma = roundToTwo(deltaSmaCalc.run(deltaVal));
	    
	    //if delta value != 0 and above thresh && sqrt is above thresh && smaChange 
	    //isn't allready 1, turm smaChange into 1 for start walking detection
	    //and the other way around for stop walking detection
	    if (deltaSma !== 0) {
	      if (deltaSma > onDeltaThresh && sqrtSma > offSqrtThresh && smaChange !== 1) {
	        setSmaChange(1);
	      }
	      else if (deltaSma < offDeltaThresh && sqrtSma < onSqrtThresh && smaChange !== 0) {
	        setSmaChange(0);
	      }
	    }  
	    prevSma = sqrtSma;
		}

		//scheduler to execute checkUpdate every 50ms
		const scheduler = setInterval(_=> {
			checkUpdate()
		},50)
		return () => {
			clearInterval(scheduler);
			// console.log("farao")
		}
	},[smaChange]);

	
	//when the smaChange-variable changes: re-render this component
	useEffect(() => {
    setData(smaChange);
	},[smaChange]);

	//send the data-value to the props, to send it to the audioPlayer
  props.changeStatus(data);


	return(
		<div>
			<PermissionButton/>
		</div>
	)
}

export default MotionTracker;