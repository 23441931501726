import React from 'react';
import {useSessionStorage} from './hooks';

const PermissionButton = props => {
  //variable that checks if it's necessary to ask for permission (only IOS 13+)
  const permissionNeeded = (typeof DeviceMotionEvent.requestPermission === 'function')?true:false;
  // const [permissionAsked,setPermissionAsked] = useState(false);
  // const [permissionState,setPermissionState] = useState(false);
  const [permissionState,setPermissionState] = useSessionStorage('permission',false);

  // useEffect(() => {

  // //check if the permission is given, so the button might disappear
  // const checkActivePermission = _ => {
  //   if (permissionNeeded && permissionAsked) {
  //     DeviceMotionEvent.requestPermission().then(permState => {
  //       if (permState === 'granted') {
  //         setPermissionState(true);
  //       }
  //       else {
  //         setPermissionState(false);
  //       }
  //     });
  //   }
  // };

  // //check every second if the permission is given
  // const interval = setInterval (() => {
  //   checkActivePermission();
  // },1000);

  // return () => clearInterval(interval);

  // })

  //function called after pressing the button.
  const askPermission = _=> {
    if (permissionNeeded) {
      // setPermissionAsked(true);
      DeviceMotionEvent.requestPermission().then(permState => {
        if (permState === 'granted') {
            window.addEventListener('devicemotion', () => {});  
            setPermissionState(true);
        }
        else {
          console.log("not granted");
          return false;
        }
      }).catch(console.error);
    } 
    else {
      window.addEventListener('devicemotion', () => {});
    }
  }

  if (permissionNeeded && !permissionState) {
    return (
      <div id="permission"> 
        <br/>
        We hebben toestemming nodig om beweginssensoren van je telefoon te gebruiken. <br/>
        Klik hieronder om deze toestemming te geven<br/>
        <br/><span id="perm" onClick={askPermission}>Klik hier!</span><br/><br/>
      </div>
    )
  }
  else {
    return <div>&nbsp;</div>
  }
}

export default PermissionButton;