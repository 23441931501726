import React, {useState} from 'react';
import AudioPlayer from './utils/audioPlayer';
import MotionTracker from './utils/motionTracker';

import './App.css';
let prevState = 2;

function App() {
  
  const [status, setStatus] = useState(2);
  
  
  const changeState = data => {
    if (prevState !== data) {
      setStatus(data);
      prevState = data;
    }
  }
  return (
    <div>
      <div id='wrapper'>
        <AudioPlayer playState={status}/>
      </div>
      <div id='tracker'>
        <MotionTracker changeStatus={changeState} />
      </div>
    </div>
  );
}

export default App;
