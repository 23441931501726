import React, {useEffect} from 'react';
import {allPlayers, allStopPlayers} from './allPlayers';
import {useSessionStorage} from './hooks';
let player,sPlayer;

const CompSelector = props => {
	const [comp, setComp] = useSessionStorage('composition',0);

	const chooseDifferentComp = e => {
		setComp(parseInt(e.target.value));
	}

	const changeCompSelection = e => {
		let chosenComp = e.target.value
		setComp(chosenComp);		
	}

	useEffect(_ => {
		console.log("hehfeauihfewaiuhf!");
		player = allPlayers[comp-1];
		sPlayer = allStopPlayers[comp-1];
		props.setStartPlayer(player);
		props.setStopPlayer(sPlayer);
		console.log(comp,player,sPlayer)
	},[comp,props]);

	if (comp === 0) {
		return (
			<div className="CompSelect">
				<table>
					<tbody>
						<tr>
							<td>
								<span>kies seizoen</span>
							</td>
						</tr>
						<tr>
							<td>
								<button onClick={changeCompSelection} value={1}>Winter</button>
								<button onClick={changeCompSelection} value={2}>Spring</button>
								<button onClick={changeCompSelection} value={3}>Summer</button>
								<button onClick={changeCompSelection} value={4}>Autumn</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
	else {
		return (
			<div className="CompSelect">
				<button onClick={chooseDifferentComp} value={0}>Kies ander seizoen</button>
			</div>
		)
	}
}

export default CompSelector;