import React, {useEffect, useState} from 'react';
import CompSelector from './CompSelector';
import ContextRunner from './ContextRunner';
import * as Tone from "tone";
let player, stopPlayer;
let stopCounter = 0;

const AudioPlayer = props => {
	const [activeState,setActiveState] = useState(false);


	const setStartPlayer = p => {
		console.log("start player set",p);
		if (player && player.state === 'started') {
			player.stop();
		}	
		player = p;
	}

	const setStopPlayer = p => {
		console.log("stop player set",p)
		stopPlayer = p;
	}

	const reactivate = status => {
		console.log("reactivate",status)
		if (status) {
			Tone.context.resume();
			if (!activeState)setActiveState(true);
		}
	}

  useEffect(_ => {
  	console.log(Tone.context.state);
	  if (props.playState === 1 && Tone.context.state === 'running') {
	  	console.log("doPlay");
	  	stopPlayer.stopAll();
	  	if (player.loaded) {
	  		player.toDestination();
	  		Tone.start();
	  		console.log("with player",player.state);
		  	player.start()
		  	console.log("with player started",player.state);
	  	}
	  }
	  else if (props.playState === 0 && Tone.context.state === 'running') {
	  	console.log("doStop");
	  	stopPlayer.player(stopCounter).start();
	  	stopCounter = (stopCounter > 1)?0:stopCounter+1;
	  	player.stop();
	  }	
  },[props.playState])
	

	if (props.data !== 0) {	
		return (
			<div id="audioPlayer">
				<CompSelector 
					setStartPlayer={setStartPlayer} 
					setStopPlayer={setStopPlayer}
				/>
				<ContextRunner 
					reactivate={reactivate}
				/>
			</div>
		)
		
	}
};

export default AudioPlayer;
